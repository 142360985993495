
import InputValidator from "../../scripts/partials/InputValidator.js";

export default function CommentForm(formElement, query) {
    var $ = query;


    formElement.setAttribute("novalidate", true);
    const url = formElement.action;
    
    const inputsValidating = formElement.querySelector(".js-comment-form-textarea");
    const inputsValidators = [];
    const radioContainer = formElement.querySelector(".js-comment-form-rating");
    const inputRadioGroup = formElement.getElementsByClassName("js-rating-input");
    const resultComment = document.querySelector(".js-result-comment");

    if(resultComment && !resultComment.classList.contains("is-hidden")){
        fillingCommentForm();
    }

    function fillingCommentForm(){
        console.log("filling");
        const commentText = resultComment.querySelector(".comment__text").textContent || resultComment.querySelector(".comment__text").innerText;
        const commentRating = resultComment.querySelector(".comment__rating-value").textContent || resultComment.querySelector(".comment__rating-value").innerText;

        inputsValidating.value = commentText;

        if(!inputsValidating.classList.contains("text-field__input_filled")){
            inputsValidating.classList.add("text-field__input_filled")
        }

        console.log("filling", commentText);

        switch (commentRating){
            case "Ужасно": return changeRating("1");
            case "Плохо": return changeRating("2");
            case "Нормально": return changeRating("3");
            case "Хорошо": return changeRating("4");
            case "Отлично": return changeRating("5");
        }

        function changeRating(ratingValue){
            for (let i = 0, len = inputRadioGroup.length; i < len; i++) {
                let inputRadio = inputRadioGroup[i];

                console.log("rating", ratingValue, inputRadio.value === ratingValue);
    
                if(inputRadio.value === ratingValue){
                    inputRadio.checked = "true"
                    console.log("rating23", ratingValue);
                }
            }
    
        }

    }


    
    for (let i = 0, len = inputsValidating.length; i < len; i++) {
        const inputInvalidClass = inputsValidating[i].getAttribute("data-invalid-class");

        const inputValidator = new InputValidator({
            "domElement": inputsValidating[i],
            "requiredFlag": true,
            "errorClass": inputInvalidClass
        });
        inputsValidators.push(inputValidator);
    }

    formElement.addEventListener("submit", function(e) {
        const isValid = validateForm(inputsValidators);
        e.preventDefault();
        //this.classList.add("is-registered");
        //console.log("sdfwefewrwer", isValid);
        if (!isValid) {
            e.preventDefault();
            //console.log("sdfwefewrwer111111");
        } else {
            requestComment();
            
        }
    });

    function requestComment(){
        $.ajax({
            type: "POST",
            url: url,
            //dataType: "json",
            data: $(formElement).serialize(),
            success: function() {
                console.log("success");
                successComment();
            },
            error:  function(){
                console.log("fail");
            }
        });
    }

    function successComment(){
        const commentEmptyBlock = document.querySelector(".js-leave-comment");
        const commentResultBlock = document.querySelector(".js-result-comment");
        const commentText = commentResultBlock.querySelector(".comment__text");
        const commentStars = commentResultBlock.getElementsByClassName("comment__star-icon");
        const commentRatingText = commentResultBlock.querySelector(".comment__rating-value");
        const modalBoxComment = document.getElementById("modal-box-comment");
        let rating = modalBoxComment.querySelector("input[name='rating']:checked").value;
        let text = modalBoxComment.querySelector(".js-comment-form-textarea").value;
        const bodyWrapper = document.body;
        const sideBar = document.querySelector(".js-account-order-sidebar");
        const heightSidebar = sideBar.clientHeight;

        if(commentEmptyBlock && !commentEmptyBlock.classList.contains("is-hidden")){
            commentEmptyBlock.classList.add("is-hidden");

            /*let heightContainer = document.querySelector(".js-order-card-container").clientHeight;
            sideBar.style.position ="absolute";
            sideBar.style.top = (heightContainer + 300 - heightSidebar) + "px";*/
        }
        

        if(commentResultBlock.classList.contains("is-hidden")){
            commentResultBlock.classList.remove("is-hidden");
        }

        for (let i = 0, len = commentStars.length; i < len; i++) {
            let star = commentStars[i];
            console.log("star", star);
            if(i <= (rating - 1)){
                //console.log(star, star.classList.contains("is-active"));
                if(star !== null && !star.classList.contains("is-active")){
                    star.classList.add("is-active");
                }
            } else {
                if(star !== null && star.classList.contains("is-active")){
                    star.classList.remove("is-active")
                }
            }
        }

        commentText.innerText = text;

        bodyWrapper.classList.remove("no-scrolling");
        modalBoxComment.classList.remove("is-show");

        let heightContainer = document.querySelector(".js-order-card-container").clientHeight;
        sideBar.style.position ="absolute";
        sideBar.style.top = (heightContainer + 300 - heightSidebar) + "px";

        switch (rating){
            case "1": return commentRatingText.innerText ="Ужасно";
            case "2": return commentRatingText.innerText = "Плохо";
            case "3": return commentRatingText.innerText = "Нормально";
            case "4": return commentRatingText.innerText = "Хорошо";
            case "5": return commentRatingText.innerText = "Отлично";
        }
        //formElement.reset();
    }


    function validateRadio(radioGroup){
        let isValid = true;

        for (let i = 0, len = radioGroup.length; i < len; i++) {
            //const isFormElementValid = radioGroup[i].checked;
            const isFormElementValid = document.querySelector("input[name='rating']:checked");
            if (!isFormElementValid) {
                isValid = false;

                !radioContainer.classList.contains("is-invalid") ? radioContainer.classList.add("is-invalid") : null
            } else {
                radioContainer.classList.contains("is-invalid") ? radioContainer.classList.remove("is-invalid") : null
            }
        }

        return isValid;
    }

    function validateTextInputs(inputsValidators) {
        let isValid = true;

        for (let i = 0, len = inputsValidators.length; i < len; i++) {
            const isFormElementValid = inputsValidators[i].validate();
            if (!isFormElementValid) {
                isValid = false;
            }
        }

        return isValid;
    }


    function validateForm(inputsValidators) {
        let isValid = true;

        const isTextInputsValid = validateTextInputs(inputsValidators);

        if (!isTextInputsValid) {
            isValid = isTextInputsValid;
        }

        const isInputRadioValid = validateRadio(inputRadioGroup);

        if (!isInputRadioValid) {
            isValid = isInputRadioValid;
        }

        return isValid;
    }
}