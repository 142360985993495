/*
 * Third party
 */
/*import Swiper from "swiper";*/
import svg4everybody from "svg4everybody";
import $ from "jquery";
import Autosize from "autosize";
import Inputmask from "inputmask";
/*import ScrollMagic from "scrollmagic";*/
import elementClosest from "element-closest";
/*import Contacts from "../Components/contacts/Contacts.js";
import ButtonScrollTop from "../Components/button-scroll-top/ButtonScrollTop.js";*/
import HeaderFixed from "../components/header/Header.js";
import ModalBox from "../components/modal-box/ModalBox.js";
import RegistrationForm from "../components/registration-form/RegistrationForm.js";
import SignInForm from "../components/signin-form/SignInForm.js";
import RecoveryForm from "../components/recovery-form/RecoveryForm.js";
import CommentForm from "../components/comment-form/CommentForm.js";
import OrderServices from "../components/order-services/OrderServices.js";
import Tabs from "../components/personal-account/Tabs.js";
import Chat from "../components/chat/Chat.js";
import AccountOrderFixed from "../components/account-order-card/AccountOrderFixed.js";
import OrderCardFixed from "../components/order-card/OrderCardFixed.js";
import InputFile from "../components/order-services/InputFile.js";
//import StatusList from "../components/status-list/StatusList.js";


import DatetimeField from "../components/datetime-field/DatetimeField.js";
import smoothscroll from "smoothscroll-polyfill";

//import baron from "baron";
import Select from "../components/select/Select.js";
import PerformerList from "../components/performer-list/PerformerList";
import EditAccount from "../components/edit-account/EditAccount.js";
import ResultForm from "../components/result-form/ResultForm.js";
import ChangePassword from "../components/change-password/ChangePassword.js";





document.addEventListener("DOMContentLoaded", function() {

  svg4everybody(
    {polyfill: true}
  );

  elementClosest(window);

  (function (arr) {
      arr.forEach(function (item) {
          if (item.hasOwnProperty("append")) {
              return;
          }
          Object.defineProperty(item, "append", {
              configurable: true,
              enumerable: true,
              writable: true,
              value: function append() {
                  var argArr = Array.prototype.slice.call(arguments),
                      docFrag = document.createDocumentFragment();

                  argArr.forEach(function (argItem) {
                      var isNode = argItem instanceof Node;
                      docFrag.appendChild(isNode ? argItem : document.createTextNode(String(argItem)));
                  });

                  this.appendChild(docFrag);
              }
          });
      });
  })([Element.prototype, Document.prototype, DocumentFragment.prototype]);

  // from:https://github.com/jserz/js_piece/blob/master/DOM/ChildNode/remove()/remove().md
  (function (arr) {
    arr.forEach(function (item) {
      if (item.hasOwnProperty("remove")) {
        return;
      }
      Object.defineProperty(item, "remove", {
        configurable: true,
        enumerable: true,
        writable: true,
        value: function remove() {
          if (this.parentNode === null) {
            return;
          }
          this.parentNode.removeChild(this);
        }
      });
    });
  })([Element.prototype, CharacterData.prototype, DocumentType.prototype]);

  if (!("classList" in SVGElement.prototype)) {
    Object.defineProperty(SVGElement.prototype, "classList", {
      get() {
        return {
          contains: className => {
            return this.className.baseVal.split(" ").indexOf(className) !== -1;
          },
          add: className => {
            return this.setAttribute("class", this.getAttribute("class") + " " + className);
          },
          remove: className => {
            var removedClass = this.getAttribute("class").replace(new RegExp("(\\s|^)" + className + "(\\s|$)", "g"), "$2");
            if (this.classList.contains(className)) {
              this.setAttribute("class", removedClass);
            }
          }
        };
      }
    });
  }

  const inputTelMask = document.getElementById("input-edit-account-tel");

  if (inputTelMask){
      new Inputmask({"mask": "+7(999) 999-99-99"}).mask("#input-edit-account-tel");
  }

  const inputsTime = document.querySelectorAll("input[type=time]");

  if(inputsTime){
    //console.log("time", inputsTime );
    for (let i = 0, len = inputsTime.length; i < len; i++) {
      new Inputmask({"mask": "99:99"}).mask(inputsTime[i]);
    }
  }
  

  /*const inputPasswordMask = document.getElementById("input-registration-password");

  if (inputPasswordMask){
      new Inputmask({regex: "/(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{6,}/g"}).mask("#input-registration-password");
  }*/

  const headerFixedElement = document.querySelector(".js-header");
  const modalOpenBtnElement = document.querySelector(".js-open-modal-box");

  if (headerFixedElement) {
    new HeaderFixed(headerFixedElement, $);
  }

  if (modalOpenBtnElement) {
    new ModalBox();
  }

  const registrationFormElement = document.getElementById("registration-form");

  if (registrationFormElement) {
    new RegistrationForm(registrationFormElement, $);
  }

  const changepasswordElement = document.querySelector(".js-change-password-form");

  if (changepasswordElement) {
    new ChangePassword(changepasswordElement, $);
  }

  const resultFormElement = document.querySelector(".js-result-form");

  if (resultFormElement) {
    new ResultForm(resultFormElement, $);
  }

  const signInFormElement = document.getElementById("signin-form");

  if (signInFormElement) {
    new SignInForm(signInFormElement, $);
  }

  const recoveryFormElement = document.querySelector(".js-recovery-form");

  if (recoveryFormElement) {
    new RecoveryForm(recoveryFormElement, $);
  }

  /*const statusListElement = document.querySelector(".js-status-list-wrapper");

  if (statusListElement) {
    new StatusList(statusListElement, $);
  }*/

  const orderFormElement = document.getElementById("order-services-form");

  if (orderFormElement) {
    new OrderServices(orderFormElement, $);
  }

  const commentFormElement = document.getElementById("comment-form");

  if (commentFormElement) {
    new CommentForm(commentFormElement, $);
  }

  const editFormElement = document.querySelector(".js-edit-account-form");

  if(editFormElement){
    new EditAccount(editFormElement, $);
  }

  const closeElement = document.querySelector(".js-cookie-close");
  const cookieBlockElement = document.querySelector(".js-cookie");

  if (closeElement && localStorage) {
    if (!localStorage.getItem("fcookie")) {
      cookieBlockElement.classList.remove("is-hidden");
    }
    closeElement.addEventListener("click", function () {
      localStorage.setItem("fcookie", 1);
      cookieBlockElement.classList.add("is-hidden");
    });
  }

  const datetimeFieldElement = document.querySelector(".js-datepicker-form");
  //console.log("2", datetimeFieldElement );
  if (datetimeFieldElement) {
    //console.log("1", $("#input-order-services-date"));

    
    //$("#input-order-services-date").datepicker();
    new DatetimeField(datetimeFieldElement);
  }

  const tabsBlockElement = document.querySelector(".js-tabs");
  
  if(tabsBlockElement){
      new Tabs(tabsBlockElement);
  }

  const chatBlockElements = document.getElementsByClassName("js-chat-form");

  for (let i = 0, len = chatBlockElements.length; i < len; i++) {
    new Chat(chatBlockElements[i], $);
  }

  const inputFileElements = document.getElementsByClassName("js-order-services-input-file");

  for (let i = 0, len = inputFileElements.length; i < len; i++) {
    new InputFile(inputFileElements[i]);
  }
  
  const textareaElementsBlock = document.getElementsByClassName("js-chat-textarea-autosize");

  if(textareaElementsBlock.length) {
    new Autosize(textareaElementsBlock);
  }

  const textareaElements = document.getElementsByClassName("js-textarea-autosize");

  if(textareaElements.length) {
      new Autosize(textareaElements);
  }

  //const textareaOrder = document.getElementById("input-order-services-description");
  
  //if(textareaOrder.length) {
    //new Autosize(textareaOrder);
  //}


  const accountOrderBlock = document.querySelector(".js-account-order-card");

  if(accountOrderBlock){
    new AccountOrderFixed(accountOrderBlock);
  }

  const orderCardBlock = document.querySelector(".js-order-card-container");

  if(orderCardBlock){
    new OrderCardFixed(orderCardBlock);
  }


  const performersElements = document.getElementsByClassName("js-performer-item-scrolled");

  if(performersElements){
      for (let i = 0, len = performersElements.length; i < len; i++) {
          new PerformerList(performersElements[i]);
      }
  }

  initSelects();

  function initSelects() {
    const selectsElements = document.querySelectorAll(".js-select");

    //console.log("secelt", selectsElements);

    for (let i = 0, len = selectsElements.length; i < len; i++) {
      new Select(selectsElements[i]);
    }
  }




  

  smoothscroll.polyfill();

});

window.addEventListener("load", function () {
  
  let promoOverlay = document.getElementsByClassName("js-promo-overlay")[0];
  
  if (promoOverlay){
    promoOverlay.classList.add("is-animation");
  }
    
});
