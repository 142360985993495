import baron from "baron";

export default function ModalBox() {

    let modalOpenBtns = document.getElementsByClassName("js-open-modal-box");
    let modalCloses = document.getElementsByClassName("js-modal-box-close");
    let modalOverlays = document.getElementsByClassName("js-modal-box-overlay");
    let modalBoxs = document.getElementsByClassName("js-modal-box");
    let bodyWrapper = document.body;

    const resultWrapper = document.getElementById("order-result-wrapper");
    console.log("erwerewr", resultWrapper && resultWrapper.classList.contains("is-append"));

    if(resultWrapper){

        resultWrapper.addEventListener("click", function(e) {
            
            let targetElement = e.target;
            
            if(resultWrapper.classList.contains("is-append") && targetElement.closest(".js-open-modal-box.is-edit")){
                console.log("12");
                clickModalOpenBtn(e);
            }
        })

    }
    //let registredLink = document.querySelector(".js-registration-form-go-to-login-form");
    

    
    for(let i = 0, len = modalOpenBtns.length; i < len; i++) {
        
        let modalOpenBtn = modalOpenBtns[i];

        modalOpenBtn.addEventListener("click", function(e) {

            clickModalOpenBtn(e);
        });
    }

    function clickModalOpenBtn(e){
        console.log("55");
        let modalId = e.target.closest(".js-open-modal-box").getAttribute("data-id") + "";
        let addClass;
        //console.log("sfssfd", e.target, modalId);

        if(e.target.closest(".js-open-modal-box").getAttribute("add-class")){
            addClass = e.target.closest(".js-open-modal-box").getAttribute("add-class");
        }

        let modalBoxOpen = document.getElementById(modalId);

        for(let i = 0, len = modalBoxs.length; i < len; i++) {
            let modalBox = modalBoxs[i];

            //modalBoxScroll(modalBox);

            //console.log("111111", modalBox, modalBoxOpen, modalBox === modalBoxOpen, !modalBox.classList.contains("is-show"));

            if(modalBox === modalBoxOpen && !modalBox.classList.contains("is-show")) {
                showModalBox(modalBox, addClass);
            } else {
                modalBox.classList.remove("is-show");
                
                if(modalBox.hasAttribute("data-add-class")){
                    //console.log("удаление333");
                    let removeAddClass = modalBox.getAttribute("data-add-class");
                    modalBox.classList.remove(removeAddClass);
                    modalBox.removeAttribute("data-add-class");
                }
            }
        }
    }

    for(let i = 0, len = modalCloses.length; i < len; i++) {
        
        let modalClose = modalCloses[i];

        modalClose.addEventListener("click", function(e) {
            let modalBox = e.target.closest(".js-modal-box");
    
            hiddenModalBox(modalBox);
        });
    }

    for(let i = 0, len = modalOverlays.length; i < len; i++) {
        
        let modalOverlay = modalOverlays[i];

        modalOverlay.addEventListener("click", function(e) {
            let modalBox = e.target.closest(".js-modal-box");
    
            hiddenModalBox(modalBox);
        });
    
    }

    
    function showModalBox(modalBox, addClass){

        let bodyModalBox = modalBox.querySelector(".modal-box__body");

        let heightModalBox = bodyModalBox.clientHeight;
        let maxHeightModalBox = vhToPixels (100) - 102;

        if(heightModalBox === maxHeightModalBox){
            bodyModalBox.style.height = maxHeightModalBox + "px";
        }

        function vhToPixels (vh) {
            return Math.round(window.innerHeight / (100 / vh));
        }

        console.log("heightModalBox ",heightModalBox, maxHeightModalBox);


        if(!modalBox.classList.contains("is-show")){
            modalBox.classList.add("is-show");
            bodyWrapper.classList.add("no-scrolling");
            
            if(!modalBox.classList.contains(addClass) && addClass){
                modalBox.classList.add(addClass);
                modalBox.setAttribute("data-add-class", addClass);
            }
        }

    }

    function hiddenModalBox(modalBox){
        if(modalBox.classList.contains("is-show")){
            modalBox.classList.remove("is-show");
            bodyWrapper.classList.remove("no-scrolling");
        }

        if(modalBox.hasAttribute("data-add-class")){
            //console.log("удаление222");
            let removeAddClass = modalBox.getAttribute("data-add-class");
            modalBox.classList.remove(removeAddClass);
            modalBox.removeAttribute("data-add-class");
        }
    }

    
    for(let i = 0, len = modalBoxs.length; i < len; i++) {
        let modalBox = modalBoxs[i];

        modalBoxScroll(modalBox);
    }

    function modalBoxScroll(baseElement){
        const modalBoxBaseClass="modal-box";

        const baronScrollerContainer = baseElement.querySelector(".js-modal-box-root");

        const modalBoxScrollTrack = document.createElement("div");
        modalBoxScrollTrack.className = modalBoxBaseClass + "__scrolltrack";

        const modalBoxScrollBar = document.createElement("div");
        modalBoxScrollBar.className = modalBoxBaseClass + "__scrollbar";

        modalBoxScrollTrack.appendChild(modalBoxScrollBar);
        baronScrollerContainer.appendChild(modalBoxScrollTrack);

        const customScroller = baron({
            cssGuru: true,
            root: baronScrollerContainer,
            scroller: baronScrollerContainer,
            barOnCls: modalBoxBaseClass + "__scroll-content_scrolling",
            bar: modalBoxScrollBar,
            direction: "v"
        });

        customScroller.controls({
            track: modalBoxScrollTrack
        });

        baronScrollerContainer.style.display = "block";
        customScroller.update();
        baronScrollerContainer.style.display = "";

        const modalBoxHeader = baseElement.querySelector(".modal-box__head");

        baronScrollerContainer.onscroll = function() {
            if (baronScrollerContainer.scrollTop > 0) {
                modalBoxHeader.classList.add("is-fixed");
            }
            else {
                modalBoxHeader.classList.remove("is-fixed");
            }
        };
    }
    
}