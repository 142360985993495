import InputValidator from "scripts/partials/InputValidator.js";
//import Inputmask from "inputmask";

export default function EditAccount(form, query) {
    var $ = query;

    const formElement = form;
    formElement.setAttribute("novalidate", true);
    //const url = formElement.action;

    let inputFile = formElement.querySelector(".js-edit-account-input-files");
    let avatar = formElement.querySelector("#edit-account-avatar-preview");
    let labelFile = formElement.querySelector(".js-edit-account-label-file");

    let filesCurrent;

    let AddFileButton = document.getElementById("edit-account-resume-file");
    let AddFileWrapper = document.querySelector(".js-edit-account-label-file-resume");
    let fileList = document.getElementById("edit-account-list-file");
    let itemPreviewTemplate;

    if(fileList){
        itemPreviewTemplate = fileList.removeChild(fileList.getElementsByClassName("js-edit-account-item")[0]);

        addChange();
    }
    

    const visibilityPassword = formElement.querySelector(".js-visibility-password-btn");
    const inputsPassword = formElement.getElementsByClassName("js-edit-account-form-input-password");
    const inputPassword = formElement.querySelector("#input-edit-account-password");
    const inputRepeatPassword = formElement.querySelector("#input-edit-account-repeat-password");
    const inputTel = formElement.querySelector("#input-edit-account-tel");
    const inputPatronymic = formElement.querySelector(".js-edit-account-form-input-not-required");

    const inputsValidating = formElement.querySelectorAll(".js-edit-account-form-input");
    const inputsValidators = [];
    //console.log(formElement, inputFile, avatar);

    inputPatronymic.addEventListener("change", function(){
        let valueInput = this.value;
        console.log("ff", valueInput);

        if(valueInput === ""){
            if(this.classList.contains("text-field__input_filled")){
                this.classList.remove("text-field__input_filled");
            }
        } else {
            if(!this.classList.contains("text-field__input_filled")){
                this.classList.add("text-field__input_filled");
            }
        }
    });

    visibilityPassword.addEventListener("click", function(){

        for (let i = 0, len = inputsPassword.length; i < len; i++) {
            const inputPassword = inputsPassword[i];

            if (inputPassword.getAttribute("type") === "password") {
                inputPassword.setAttribute("type", "text");
                this.classList.add("is-show");
            } else {
                inputPassword.setAttribute("type", "password");
                this.classList.remove("is-show");
            }
        }
    });

    for (let i = 0, len = inputsValidating.length; i < len; i++) {
        const inputInvalidClass = inputsValidating[i].getAttribute("data-invalid-class");

        const inputValidator = new InputValidator({
            "domElement": inputsValidating[i],
            "requiredFlag": true,
            "errorClass": inputInvalidClass
        });
        inputsValidators.push(inputValidator);
    }

    formElement.addEventListener("submit", function(e) {
        const isValid = validateForm(inputsValidators);
        e.preventDefault();

        if (!isValid) {
            e.preventDefault();

        } else {
            //requestEditAccount();
			formElement.removeEventListener("submit", e, false);
			formElement.submit();

        }
    });

    /*function requestEditAccount(){
        $.ajax({
            type: "POST",
            url: url,
            dataType: "json",
            data: $(formElement).serialize(),
            success: function() {
                console.log("success");
            },
            error:  function(){
                console.log("fail");
            }
        });
    }*/

    inputFile.addEventListener("change", function(){
        if(!validateSize(this,1)){
            console.log("файл больше 1мб");
        } else {
            readUrl(this);
        }
    });

    function readUrl(input){
        if (input.files && input.files[0]) {
            var reader = new FileReader();

            reader.onload = function (e) {
                //$("#image").attr('src', e.target.result);
                let urlAvatar = "url(" + e.target.result + ")";
                $(avatar).css("backgroundImage", urlAvatar);

                if($(avatar).hasClass("no-avatar")){
                    $(avatar).removeClass("no-avatar");
                    $(labelFile).text("Сменить фото");
                }
            };
    
            reader.readAsDataURL(input.files[0]);
        }
    }

    function validateSize(fileInput,size) {
        var fileObj, oSize;
        fileObj = fileInput.files[0];
        /*if ( typeof ActiveXObject == "function" ) { // IE
            fileObj = (new ActiveXObject("Scripting.FileSystemObject")).getFile(fileInput.value);
        }else {
            fileObj = fileInput.files[0];
        }*/

        oSize = fileObj.size;
        if(oSize > size * 1024 * 1024){
            return false
        }
        return true;
    }

    function validatePassword(inputPassword, inputRepeatPassword) {
        let isValid;

        const inputInvalidClass = inputPassword.getAttribute("data-invalid-class");
        let isInvalidPassword = inputPassword.classList.contains(inputInvalidClass);
        let isInvalidRepeatPassword = inputRepeatPassword.classList.contains(inputInvalidClass);

        let reg = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*+_~-]).{6,}$/;
        let regResult =  reg.test(inputPassword.value);

        if(inputPassword.value.length === 0 && inputRepeatPassword.value.length === 0){
            
            if (isInvalidPassword){
                inputPassword.classList.remove(inputInvalidClass);
            }

            if (isInvalidRepeatPassword){
                inputRepeatPassword.classList.remove(inputInvalidClass);
            }

            isValid = true;
        } else {
            if(regResult && (inputPassword.value == inputRepeatPassword.value)){
            
                if (isInvalidPassword){
                    inputPassword.classList.remove(inputInvalidClass);
                }
    
                if (isInvalidRepeatPassword){
                    inputRepeatPassword.classList.remove(inputInvalidClass);
                }
    
                isValid = true;
                console.log("asdasdasdad");
    
            } else {
                
                if(inputPassword.value !== inputRepeatPassword.value){
    
                    inputRepeatPassword.classList.add(inputInvalidClass);
                    
                } else {
                    inputRepeatPassword.classList.remove(inputInvalidClass);
                }
    
    
                if(!regResult){
                    inputPassword.classList.add(inputInvalidClass);
                } else {
                    inputPassword.classList.remove(inputInvalidClass);
                }
    
                isValid = false;
            }
        }

        console.log("valiPassword", isValid);
        return isValid;
    }
    
    function validateTextInputs(inputsValidators) {
        let isValid = true;

        for (let i = 0, len = inputsValidators.length; i < len; i++) {
            const isFormElementValid = inputsValidators[i].validate();
            if (!isFormElementValid) {
                isValid = false;
            }
        }

        return isValid;
    }



    function validateTelInput(inputTel) {
        let isValid;
        let input = inputTel;

        if(input.inputmask && input.inputmask.isComplete()){
            isValid = true;
            if(input.classList.contains("text-field__input_invalid")){
                input.classList.remove("text-field__input_invalid");
            }
        } else {
            isValid = false;
            if(!input.classList.contains("text-field__input_invalid")){
                input.classList.add("text-field__input_invalid");
            }
        }



        return isValid;
    }

    function validateForm(inputsValidators) {
        let isValid = true;

        const isTextInputsValid = validateTextInputs(inputsValidators);

        if (!isTextInputsValid) {
            isValid = isTextInputsValid;
        }

        const isInputTelValid = validateTelInput(inputTel);

        if (!isInputTelValid) {
            isValid = isInputTelValid;
        }

        const isInputPasswordValid = validatePassword(inputPassword, inputRepeatPassword);

        if (!isInputPasswordValid) {
            isValid = isInputPasswordValid;
        }

        return isValid;
    }


    /* input File*/

   
    

    function addChange() {
        AddFileButton.addEventListener("change", houmFunction);
    }

    function houmFunction() {
        filesCurrent = this.files;
        addHTML();
    }

    function addHTML() {
        let currentFile = filesCurrent[0];
        let itemPreview = itemPreviewTemplate.cloneNode(true);

        let text = currentFile.name;
        let sizeFile = (currentFile.size/1024).toFixed(1) + " KB";


        itemPreview.querySelector(".js-edit-account-file-name").text = text;
        itemPreview.querySelector(".js-edit-account-file-size").append(sizeFile);

        fileList.append(itemPreview);

        if(!AddFileWrapper.classList.contains("is-hidden")){
            AddFileWrapper.classList.add("is-hidden");
        }


        itemPreview.addEventListener("click", function(e) {
            let targetElement = e.target;
            //let currentCounter = addressList.indexOf(counter);

            if(targetElement.closest(".js-edit-account-file-delete")){
                itemPreview.remove();

                AddFileButton.value = "";    


                if(AddFileWrapper.classList.contains("is-hidden")){
                    AddFileWrapper.classList.remove("is-hidden");
                }

                //console.log("files-delete", AddFileButton.files);
            }  
               
            }
        );

        //console.log("files", AddFileButton.files);
    }
    console.log("aaaaa", fileList );

    if(fileList  && fileList.querySelectorAll(".js-edit-account-item").length > 0){
        console.log("это изначальный файл");

        let currentFile = fileList.querySelectorAll(".js-edit-account-item")[0];
        
        currentFile.addEventListener("click", function(e) {
            let targetElement = e.target;
            //let currentCounter = addressList.indexOf(counter);

            if(targetElement.closest(".js-edit-account-file-delete")){
                currentFile.remove();

                AddFileButton.value = "";    


                if(AddFileWrapper.classList.contains("is-hidden")){
                    AddFileWrapper.classList.remove("is-hidden");
                }
            }  
               
            }
        );

    }


}    