import InputValidator from "scripts/partials/InputValidator.js";


export default function SignInForm(formElement, query) {
    var $ = query;

    formElement.setAttribute("novalidate", true);
    const url = formElement.action;

    const visibilityPassword = formElement.querySelector(".js-signin-form-visibility-password-btn");
    const inputsPassword = formElement.getElementsByClassName("js-signin-form-input-password");
    const inputRole = formElement.querySelector("input[name=role]");
    const modalBox = document.getElementById("modal-box-login");

    //const recoveryButton = formElement.querySelector(".js-signin-form-recovery-button");
    //const buttonGoToRegistrationForm =  formElement.querySelector(".js-signin-form-go-to-registration-form");

    /*recoveryButton.addEventListener("click", function(e) {
        e.preventDefault();
        if (recoveryButtonClickHandler) {
            recoveryButtonClickHandler();
        }
    });*/


    visibilityPassword.addEventListener("click", function(){

        for (let i = 0, len = inputsPassword.length; i < len; i++) {
            const inputPassword = inputsPassword[i];
    
            if (inputPassword.getAttribute("type") === "password") {
                inputPassword.setAttribute("type", "text");
                this.classList.add("is-show");
            } else {
                inputPassword.setAttribute("type", "password");
                this.classList.remove("is-show");
            }
        }
    });



    const inputsValidating = formElement.querySelectorAll(".js-signin-form-input");
    const inputsValidators = [];

    for (let i = 0, len = inputsValidating.length; i < len; i++) {
        const inputInvalidClass = inputsValidating[i].getAttribute("data-invalid-class");

        const inputValidator = new InputValidator({
            "domElement": inputsValidating[i],
            "requiredFlag": true,
            "errorClass": inputInvalidClass
        });
        inputsValidators.push(inputValidator);
    }

    formElement.addEventListener("submit", function(e) {
        
        const isValid = validateForm(inputsValidators);
        //e.preventDefault();
        console.log("1");
        if (!isValid) {
            e.preventDefault();
            console.log("2");
        } else {
            e.preventDefault();
            console.log("3");
            inputRole.value = modalBox.classList.contains("is-expert") ? 1 : 0;
            requestSignIn();
        }
    });


    function requestSignIn(){

        //console.log("dfdfdsfsdf", $(formElement));

        $.ajax({
            type: "POST",
            url: url,
            dataType: "json",
            data: $(formElement).serialize(),
            success: function(data) {
                //console.log('success');
                if (!data.errors) {
                    //console.log('yes');
                    signInAccout();
                } else {
                    //console.log('no');
                    formElement.setAttribute("novalidate", true);

                    for (let i = 0, len = inputsValidating.length; i < len; i++) {
                        const inputInvalidClass = inputsValidating[i].getAttribute("data-invalid-class");
                        inputsValidating[i].classList.add(inputInvalidClass);
                        
                    }

                    
                }
            },
            error:  function(){
                console.log("fail");
            }
        });
    }

    function  signInAccout(){
        //let accountBtn = document.querySelector(".js-header-account-button");
        //let accountLogin = document.querySelector(".js-header-account-login");

        modalBox.classList.remove("is-show");
        document.body.classList.remove("no-scrolling");
        window.location.reload();
        //accountBtn.classList.add("is-hidden");
        //accountLogin.classList.add("is-show");
        
    }

    function validateTextInputs(inputsValidators) {
        let isValid = true;

        for (let i = 0, len = inputsValidators.length; i < len; i++) {
            const isFormElementValid = inputsValidators[i].validate();
            if (!isFormElementValid) {
                isValid = false;
            }
        }

        return isValid;
    }

    /*function setTextInputsValid(inputsValidators) {
        for (let i = 0, len = inputsValidators.length; i < len; i++) {
            inputsValidators[i].setValid();
        }
    }*/

    function validateForm(inputsValidators) {
        const isValid = validateTextInputs(inputsValidators);
        return isValid;
    }
}