
export default function ChangePassword(formElement, query) {
    var $ = query;


    formElement.setAttribute("novalidate", true);
    const url = formElement.action;
   
    const visibilityPassword = formElement.querySelector(".js-visibility-password-btn");
    const inputsPassword = formElement.getElementsByClassName("js-change-password-form-input-password");
    const inputPassword = formElement.querySelector("#input-change-password-password");
    const inputRepeatPassword = formElement.querySelector("#input-change-password-repeat-password");
    
    

    visibilityPassword.addEventListener("click", function(){

        for (let i = 0, len = inputsPassword.length; i < len; i++) {
            const inputPassword = inputsPassword[i];
    
            if (inputPassword.getAttribute("type") === "password") {
                inputPassword.setAttribute("type", "text");
                this.classList.add("is-show");
            } else {
                inputPassword.setAttribute("type", "password");
                this.classList.remove("is-show");
            }
        }
    });

  

    formElement.addEventListener("submit", function(e) {
        const isValid = validateForm();
        e.preventDefault();
        //this.classList.add("is-registered");
        console.log("sdfwefewrwer", isValid);
        if (!isValid) {
            e.preventDefault();
            console.log("sdfwefewrwer111111");
        } else {
            requestChangePassword();
            
        }
    });

    function requestChangePassword(){
        $.ajax({
            type: "POST",
            url: url,
            dataType: "json",
            data: $(formElement).serialize(),
            success: function(data) {
                console.log("success");
                if (!data.errors) {
                    console.log("yes");
                    successChangePassword();
                    //window.location.reload();
                    //formElement.classList.add("is-registered");
                } else {
                    console.log("no");
                    formElement.setAttribute("novalidate", true);

                    //const inputInvalidClass = inputEmail.getAttribute("data-invalid-class");
                    //inputEmail.classList.add(inputInvalidClass);
                }
            },
            error:  function(){
                console.log("fail");
            }
        });
    }

    function successChangePassword() {
        formElement.reset();
        formElement.classList.add("is-success");
    }

    function validatePassword(inputPassword, inputRepeatPassword) {
        let isValid;

        const inputInvalidClass = inputPassword.getAttribute("data-invalid-class");
        let isInvalidPassword = inputPassword.classList.contains(inputInvalidClass);
        let isInvalidRepeatPassword = inputRepeatPassword.classList.contains(inputInvalidClass);
        

        let reg = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*+_~-]).{6,}$/;
        let regResult =  reg.test(inputPassword.value);

        if(regResult && (inputPassword.value == inputRepeatPassword.value)){
            
            if (isInvalidPassword){
                inputPassword.classList.remove(inputInvalidClass);
            }

            if (isInvalidRepeatPassword){
                inputRepeatPassword.classList.remove(inputInvalidClass);
            }

            isValid = true;
            console.log("asdasdasdad");

        } else {
            
            if(inputPassword.value !== inputRepeatPassword.value){

                inputRepeatPassword.classList.add(inputInvalidClass);
                
            } else {
                inputRepeatPassword.classList.remove(inputInvalidClass);
            }


            if(!regResult){
                inputPassword.classList.add(inputInvalidClass);
            } else {
                inputPassword.classList.remove(inputInvalidClass);
            }

            isValid = false;
        }

        console.log("valiPassword", isValid);
        return isValid;
    }
   

    function validateForm() {
        let isValid = true;

        const isInputPasswordValid = validatePassword(inputPassword, inputRepeatPassword);

        if (!isInputPasswordValid) {
            isValid = isInputPasswordValid;
        }

        return isValid;
    }
}