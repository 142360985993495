
export default function ResultForm(formElement, query) {
    var $ = query;

    let maxFileSize = 20 * 1024 * 1024;
    let maxFilesCount = 10;

    let filesGroup = [];
    let addressList =[];
    let index = 0;

    let AddFileButton = formElement.getElementsByClassName("js-result-form-input-files")[0];
    let fileList = document.getElementById("result-form-files-list");
    let fileItem = fileList.getElementsByClassName("js-file-item");
    let resultWrapper = document.getElementById("order-result-wrapper");
    let bodyWrapper = document.body;
    let resultEmptyBlock = resultWrapper.querySelector(".order-result");

    let editFileList_ = formElement.getElementsByClassName("js-result-form-add-files")[0];
    var editFileList = editFileList_ ? editFileList_.value : "[]";
    let deleteFiles = formElement.getElementsByClassName("js-result-form-input-delete-files")[0];
    let arrDeleteFiles = [];

    if(editFileList !== ""){
       window.addEventListener("load", function () {
            editFiles();
      })
      
    }
    

    if (!resultEmptyBlock.classList.contains("is-hidden")) {
        //console.log("211212");
        filledResultForm();
    }

    function filledResultForm(){

        let inputCloud = formElement.querySelector("#input-result-form-cloud");
        let textareaComment = formElement.querySelector("#input-result-form-description");
        //console.log("dasddaa", formElement, formElement.querySelector("#input-result-form-cloud"));
        let resultBlock = resultWrapper.querySelector(".order-result");
        let resultLink = resultBlock.querySelector(".js-order-result-cloud").querySelector(".file-list__file-link");
        let resultText = resultBlock.querySelector(".order-result__comments-text");
        
        if(resultText){
            textareaComment.value = resultText.innerText || resultText.textContent;

            if(!textareaComment.classList.contains("text-field__input_filled")){
                textareaComment.classList.add("text-field__input_filled")
            }
        }

        if(resultLink){
            inputCloud.value = resultLink.innerText || resultLink.textContent;

            if(!inputCloud.classList.contains("text-field__input_filled")){
                inputCloud.classList.add("text-field__input_filled")
            }
        }

    }

    
    let itemPreviewTemplate = fileList.removeChild(fileList.getElementsByClassName("js-file-item")[0]);

    formElement.setAttribute("novalidate", true);
    const url = formElement.action;

    addChange();

    formElement.addEventListener("submit", function(e) {

        e.preventDefault();
        var isValid = validateForm(this);

        if (!isValid) {
            e.preventDefault();
            console.log("no");
        } else {
            requestResult();
        }
    });

    function requestResult(){
		let formData = new FormData(formElement);
		console.log("request");
        $.ajax({
            type: "POST",
            url: url,
			cache: false,
			contentType: false,
			processData: false,
            data: formData,
            dataType: "json",
            success: function(data) {
                console.log("success");
                if (data.resultBody) {
                    if(!resultWrapper.classList.contains("is-append")){
                        resultWrapper.classList.add("is-append");
                    }
                    let orderResult = resultWrapper.querySelector(".order-result");
                    $(orderResult).remove();
                    $(resultWrapper).append(data.resultBody);
                }
				if (data.reload) {
					window.location=window.location;
				}
                if (data.result) {
                    console.log("5666");
                    formElement && closeModalBox(formElement);
				}
            },
            error:  function(){
                console.log("fail");
            }
        });
    }

    function closeModalBox(form) {
        let modalBox = false;

        if(form.closest(".js-modal-box")){
            modalBox = form.closest(".js-modal-box");
        }

        if(modalBox){
            modalBox.classList.remove("is-show");
            bodyWrapper.classList.remove("no-scrolling");
        }
    }

    function validateForm(form) {
        let isValid = true;

        var resultForm = form;
        var cloudField = resultForm.querySelector(".js-result-form-input");

        var valueCloud = cloudField.value;
        //console.log("555", AddFileButton.files, filesGroup.length, addressList);

        if (!(valueCloud === "") || fileItem.length > 0) {
            isValid = true;
            if(resultForm.classList.contains("is-invalid")){
                resultForm.classList.remove("is-invalid")
            }
        } else {
            isValid = false;

            if(!resultForm.classList.contains("is-invalid")){
                resultForm.classList.add("is-invalid")
            }
        }

        console.log("valueDate3333", isValid);
        return isValid;
    }


    

    function addChange() {
        AddFileButton.addEventListener("change", houmFunction);
    }

    function houmFunction() {
        let filesCurrent = this.files;

        counterFile(filesCurrent);
        inspectorARR();
        inspectorHTML();
    }

    function counterFile(filesGroupCurrent) {
        let filesGroupLength = filesGroup.length;

        for (let counter = 0; counter < filesGroupCurrent.length; counter++) {
            filesGroup[filesGroupLength + counter] = filesGroupCurrent[counter];
            addressList[filesGroupLength + counter] = index;

            index++;
        }
    }

    function inspectorARR() {
        let fullSize = 0;

        for (let counter = 0; counter < filesGroup.length; counter++) {
            fullSize += filesGroup[counter].size;

            if ((fullSize > maxFileSize) || (counter >= maxFilesCount)) {
                filesGroup.splice(counter);
                addressList.splice(counter);
                break;
            }
        }
    }

    function inspectorHTML() {
        let currentElements = fileList.getElementsByClassName("js-file-item");

        for (let counter = currentElements.length; counter < filesGroup.length; counter++) {

            addHTML(counter);
        }
    }

    function addHTML(counter) {
        let currentFile = filesGroup[counter];
        let itemPreview = itemPreviewTemplate.cloneNode(true);
        let text = currentFile.name;
        let sizeFile = (currentFile.size/1024).toFixed(1) + " KB";
        //console.log("dfsdf", currentFile);


        itemPreview.getElementsByClassName("js-file-name")[0].text = text;
        itemPreview.getElementsByClassName("js-file-size")[0].append(sizeFile);

        fileList.append(itemPreview);

        itemPreview.addEventListener("click", function() {
                let currentCounter = addressList.indexOf(counter);

                itemPreview.remove();
                filesGroup.splice(currentCounter, currentCounter);
                addressList.splice(currentCounter, currentCounter);
            }
        );
    }

    function editFiles(){
        
        let arrFiles = JSON.parse(editFileList);
        
        console.log("massive", arrFiles, arrFiles.length);
        for(let i = 0; i < arrFiles.length; i++){

            let currentFile = arrFiles[i];
            let itemPreview = itemPreviewTemplate.cloneNode(true);
            let text = currentFile.title;
            let urlFile = currentFile.url;
            let sizeFile = currentFile.size + " KB";
            itemPreview.getElementsByClassName("js-file-name")[0].text = text;
            itemPreview.getElementsByClassName("js-file-name")[0].setAttribute("href", urlFile);
            itemPreview.getElementsByClassName("js-file-size")[0].append(sizeFile);

            fileList.append(itemPreview);

            itemPreview.addEventListener("click", function(e) {
                let targetElement = e.target;
                //let currentCounter = addressList.indexOf(counter);
    
                if(targetElement.closest(".js-file-delete")){
                    itemPreview.remove();
                    arrDeleteFiles.push(currentFile.name);
                    if (deleteFiles) deleteFiles.value = arrDeleteFiles.join(";");
                    //console.log("3333", filesGroup, addressList);
                }
            });  
        }
        console.log("delete", arrDeleteFiles);
    }

}    